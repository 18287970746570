import { LOCALES } from '../locales';

const english = {
  [LOCALES.ENGLISH]: {
    hello: 'Hi, {name}!',
    exit: 'Exit',
    'app.login.welcome': 'Welcome',
    'app.login.signin': 'Sign in',
    'app.login.donthaveaccount': "Still don't have an account?",
    'app.login.loginaccount': 'Login to your account',
    'app.login.signup': 'Sign up here',
    'app.register.welcome': "Let's start",
    'app.register.registeraccount': 'Create your account',
    'app.register.signup': 'Sign up',
    'app.register.haveanaccount': 'Already have an account?',
    'app.register.signin': 'Sign in here',
    username: 'Username',
    password: 'Password',
    Username: 'Username',
    Password: 'Password',
    Welcome: 'Welcome to the administration system',
    'Configure statistics': 'Configure statistics',
    mole: 'Mole',
    total: 'Total',
    'confirm delete': 'Confirm delete',
    records: 'records',
    show: 'Show',
    of: 'of',
    page: 'Page',
    actions: 'actions',
    email: 'email',
    lastName: 'LastName',
    firstName: 'FirstName',
    users: 'Users',
    'Configure link to official website': 'Configure link to official website',
    sections: 'Sections',
    author: 'Author',
    slug: 'Slug',
    title: 'Title',
    'Post date': 'Post date',
    type: 'Type',
    subtitle: 'Subtitle',
    apply: 'Apply',
    posts: 'Posts',
    lodgings: 'Lodgings',
    Lodgings: 'Lodgings',
    dashboard: 'Dashboard',
    description: 'Description',
    name: 'Name',
    Posts: 'Posts',
    search: 'Search',
    'insert cancellation description here...': 'Insert cancellation description here...',
    'insert description here...': 'Insert description here...',
    Amenities: 'Amenities',
    Gallery: 'Gallery',
    'General data': 'General data',
    cancel: 'Cancel',
    'new lodging': 'New lodging',
    'insert check in description here...': 'insert check in description here...',
    epigraph: 'Epigraph',
    'upload photo': 'Upload photo',
    'choose file': 'Choose file',
    'page created': 'Page created',
    Pages: 'Pages',
    'new page': 'New page',
    'edit page': 'Edit page',
    'page edited': 'Page edited',
    'new section': 'New section',
    'edit section': 'Edit section',
    'section type': 'Section type',
    'You must select a type to complete the section information...':
      'You must select a type to complete the section information...',
    order: 'Order',
    Sections: 'Sections',
    pages: 'pages',
    'This section does not need more information.': 'This section does not need more information.',
    ampMap: 'ampMap',
    newsletter: 'Newsletter',
    newsletterV2: 'Newsletter V2',
    twoColumnsPubs: 'twoColumnsPubs',
    textSection: 'textSection',
    bookWidget: 'bookWidget',
    heroBanner: 'heroBanner',
    heroBannerHalf: 'Half Hero Banner',
    'insert text here...': 'Insert text here...',
    heroImage: 'Hero Image',
    cardsBand: 'cardsBand',
    cardsGrid: 'Cards Grid',
    cardsGridV2: 'Cards Grid V2',
    ampGalleryCarousel: 'ampGalleryCarousel',
    'back to pages': 'Back to pages',
    'config section': 'Config section',
    configuration: 'Configuration',
    'title must not be empty': 'Title must not be empty',
    'subtitle must not be empty': 'Subtitle must not be empty',
    'button text must not be empty': 'Button text must not be empty',
    'origin must not be empty': 'Origin must not be empty',
    'section edited': 'Section edited',
    'alternative text': 'Alternative text',
    link: 'Link',
    'button link': 'Button link',
    'text button': 'Text button',
    photo: 'Photo',
    upload: 'Upload',
    'vertical text align': 'Vertical text align',
    'horizontal text align': 'Horizontal text align',
    middle: 'Middle',
    left: 'Left',
    right: 'Right',
    'to left': 'To Left',
    'to right': 'To Right',
    'to top': 'To Top',
    'to botton': 'To Botton',
    change: 'Change',
    remove: 'Remove',
    'horizontal text align must not be empty': 'Horizontal text align must not be empty',
    'vertical text align must not be empty': 'Vertical text align must not be empty',
    'image must not be empty': 'Image must not be empty',
    'section saved': 'Section saved',
    'edit content': 'Edit content',
    'add content': 'Add content',
    text: 'Text',
    'text must not be empty': 'Text must not be empty',
    'image height': 'Image height',
    'image height should be between 250 and 400': 'Image height should be between 250 and 400',
    'src map must not be empty': 'Src map (url) must not be empty',
    'src map': 'Src map (url)',
    'post type': 'Post type',
    'postType must not be empty': 'Post type must not be empty',
    'section created': 'Section created',
    Activities: 'Activities',
    Services: 'Services',
    Promociones: 'Promotions',
    Publish: 'Publish',
    Draft: 'Draft',
    draft: 'Draft',
    status: 'Status',
    'insert content here...': 'Insert content here...',
    'new post': 'New post',
    'post type must not be empty': 'Post type must not be empty',
    'status must not be empty': 'Status must not be empty',
    'slug must not be empty': 'Slug must not be empty',
    'date must not be empty': 'Date must not be empty',
    'author must not be empty': 'Author must not be empty',
    'edit post': 'Edit post',
    Published: 'Published',
    published: 'Published',
    'feature persons': 'Feature persons',
    'feature m2': 'Feature m2',
    'feature bathroom': 'Feature bathroom',
    'feature landscape': 'Feature landscape',
    'feature sound': 'Feature sound',
    'feature image': 'Feature image',
    'free feature': 'Free feature',
    heroImageRoom: 'Hero Image Room',
    'button text': 'Button text',
    'post created': 'Post created',
    'Executive Lunches': 'Executive Lunches',
    Events: 'Events',
    Basilico: 'Basilico',
    Promotions: 'Promotions',
    lodgingDescription: 'Lodging Description',
    'avoid accents and strange symbols': 'Avoid accents and strange symbols',
    outstanding: 'Outstanding',
    'post updated': 'Post updated',
    'name must not be empty': 'Name must not be empty',
    'path must not be empty': 'Path must not be empty',
    path: 'Path',
    textSectionV2: 'Text section version 2',
    heroImageV2: 'Hero Image Version 2',
    twoColumnsCard: 'Two columns card',
    ampGalleryCarouselV2: 'Gallery Carousel Version 2',
    cardsBandV2: 'Cards band Version 2',
    cardsBandV3: 'Cards band Version 3',
    cardsBandV4: 'Cards band Version 4',
    cardsBandV5: 'Cards band Version 5',
    cardsBandV6: 'Cards band Version 6',
    cardsBandV7: 'Cards band Version 7',
    lodgingDescriptionV2: 'Lodging description Version 2',
    pretitle: 'Pretitle',
    'title 1 must not be empty': 'Title 1 must not be empty',
    'text 1 must not be empty': 'Text 1 must not be empty',
    'title 2 must not be empty': 'Title 2 must not be empty',
    'text 2 must not be empty': 'Text 2 must not be empty',
    'pdf must not be empty': 'PDF must not be empty',
    pdf: 'PDF',
    gridSection: 'Grid section',
    menuPresenter: 'Menu Presenter',
    'iframe book': 'Iframe book',
    'iframe book must not be empty': 'Iframe book must not be empty',
    height: 'Height',
    'height must not be empty': 'Height must not be empty',
    'mobile height': 'Mobile height',
    'tablet height': 'Tablet height',
    'desktop height': 'Desktop height',
    'desktop plus height': 'Big desktop height',
    'image from file': 'Image from file',
    'video from file': 'Video from file',
    'video from URL': 'Video from URL',
    'select src': 'Select source',
    invert: 'Invert',
    youtube: 'Youtube',
    vimeo: 'Vimeo',
    'select video source': 'select video source',
    'source video must not be empty': 'source video must not be empty',
    'video link must not be empty': 'video link must not be empty',
    'section title': 'section title',
    projects: 'Projects',
    tag: 'Tag',
    tags: 'Tags',
    logo: 'Logo',
    'new project': 'New project',
    'project updated': 'Project updated',
    'project created': 'Project created',
    'edit project': 'Edit project',
    'new amenitie': 'New amenitie',
    'two columns section': 'Two columns section',
    'source select must not be empty': 'Source select must not be empty',
    manual: 'Manual',
    auto: 'Auto',
    'select autoload': 'Select autoload',
    'select autoload must not be empty': 'Select autoload must not be empty',
    'link must not be empty': 'Link must not be empty',
    subject: 'Subject',
    'subject must not be empty': 'Subject must not be empty',
    from: 'From',
    to: 'To',
    'from address must not be empty': 'From must not be empty',
    'to address must not be empty': 'to address must not be empty',
    textButton: 'Button text',
    'insert email header here...': 'Insert email header here...',
    'insert email signature here...': 'Insert email signature here...',
    formSection: 'Form Section',
    formWorkWithUs: 'Form Work With Us',
    'four columns section': 'Four columns section',
    'principal title': 'Principal title',
    'timeline section': 'Timeline section',
    'two columns wide image section': 'Two columns wide image section',
    'map section': 'Map section',
    mapSection: 'Map section',
    'form section': 'Form section',
    address: 'Address',
    'modal section': 'Modal section',
    'blueprints section': 'Blueprints section',
    'floors details section': 'Floors details section',
    'delete section': 'Delete section',
    'add head field': 'add head field',
    'add row': 'add row',
    'add section': 'add section',
    modal: 'Modal',
    'insert title here...': 'Insert title here...',
    'insert subtitle here...': 'Insert subtitle here...',
    'text vertical position': 'Text vertical position',
    'text horizontal position': 'Text horizontal position',
    'title horizontal position': 'Title horizontal position',
    'text button horizontal position': 'Text button horizontal position',
    top: 'Top',
    center: 'Center',
    bottom: 'Bottom',
    'custom gradient color': 'Custom gradient color',
    GradientDirection: 'Gradient direction',
    'custom title color': 'Custom title color',
    'title color': 'Title color',
    'custom subtitle color': 'Custom subtitle color',
    icons: 'Icons',
    url: 'Url',
    'background color': 'Background color',
    cards: 'Cards',
    start: 'Start',
    end: 'End',
    area: 'Area',
    events: 'Events',
    'new event': 'New event',
    'edit event': 'Edit event',
    twoColumnsTextCarousel: 'Two Columns Text Carousel',
    'job title': 'Job title',
    quote: 'Quote',
    image: 'Image',
    video: 'Video',
    'content type': 'Content type',
    nameButtonJobs: 'NameButtonJobs',
    linkButtonJobs: 'LinkButtonJobs',
    'lateral multimedia': 'Lateral multimedia',
    conection: 'Conection',
    chatButton: 'Chat button',
    'desktop image': 'Desktop image',
    'mobile image': 'Mobile image',
    delay: 'Delay',
    newTab: 'New tab',
    borderRadiusImage: 'Border radius image',
    stackedImagesSliderV: 'Stacked Images Slider',
    stackedImagesSliderV2: 'Stacked Images Slider V2',
    stackedImagesSliderV3: 'Stacked Images Slider V3',
    stackedImagesSliderV4: 'Stacked Images Slider V4',
    basicTestimony: 'Basic Testimony',
    faqAccordion: 'FAQ Accordion',
    aswer: 'Aswer',
    question: 'Question',
    'answer&question': 'Answer & Question',
    year: 'Year',
    eventsTimeline: 'Events Timeline',
    eventsTimelineV2: 'Events Timeline V2',
    origin: 'Origin',
    thanksSubtitle: 'Thanks subtitle',
    thanksTitle: 'Thanks title',
    testimonies: 'Testimonies',
    newTestimony: 'New testimony',
    editTestimony: 'Edit testimony',
    testimony: 'Testimony',
    dividerWithTransition: 'Divider with transition',
    divider: 'Divider',
    borderRadius: 'Border radius',
    topLeft: 'Top left',
    topRight: 'Top right',
    bottomLeft: 'Bottom left',
    bottomRight: 'Bottom right',
    backgroundColorTop: 'Background color top',
    gradientColor: 'Gradient color',
    backgroundColorBotton: 'Background color bottom',
    layout: 'Layout',
    selected: 'Selected',
    'confirm select': 'Confirm action?',
    'select layout': 'Select layout',
    'layout name': 'Layout name',
    paddingTop: 'Padding top',
    paddingRight: 'Padding right',
    paddingBottom: 'Padding bottom',
    paddingLeft: 'Padding left',
    'menus gap': 'Menus gap',
    background: 'Background',
    'new tab': 'New tab',
    width: 'Width',
    align: 'Align',
    minHeight: 'Minimum height',
    maxHeight: 'Maximum height',
    gap: 'Gap',
    Logo: 'Logo',
    Style: 'Style',
    footer: 'Footer',
    info: 'Info',
    rows: 'Rows',
    'social icons': 'Social icons',
    Icons: 'Icons',
    navbar: 'NavBar',
    style: 'style',
    layout_intro_navbar: 'Next you can configure the different elements of the navigation bar.',
    layout_intro_footer: 'Next you can configure the different footer elements.',
    'layout updated': 'Layout updated',
    menus: 'Menus',
    submenus: 'Submenus',
    'select page': 'Select page',
    'select link type': 'Select link type',
    'section link': 'Section link',
    'external link': 'External link',
    'internal link': 'Internal link',
    edit: 'Edit',
    create: 'Create',
    new: 'New',
    'social icon type': 'Social icon type',
    verticalArrangement: 'Vertical arrangement',
    'initial background color': 'Initial background color',
    'end background color': 'End background color',
    marginLeft: 'Margin left',
    marginRight: 'Margin right',
    textColor: 'Text color',
    'contentType media not supported': 'ContentType media not supported',
    'button text color': 'Button text color',
    'button hover text color': 'Button hover text color',
    'button color': 'Button color',
    'button hover color': 'Button hover color',
    'button border color': 'Button border color',
    cardTextColor: 'Card text color',
    'card title color': 'Card title color',
    curriculum: 'Curriculum',
    'epigraph text': 'Epigraph text',
    'person in charge emails': 'Person in charge emails',
    'person in charge': 'Person in charge',
    'starting date': 'Starting date',
    duration: 'Duration',
    price: 'Price',
    'course type': 'Course type',
    'type must not be empty': 'Type must not be empty',
    'epigraph must not be empty': 'Epigraph must not be empty',
    'alter must not be empty': 'Alternative must not be empty',
    'responsibleEmails must not be empty': 'ResponsibleEmails must not be empty',
    'course media': 'Course media',
    'edit course': 'Edit course',
    courses: 'Courses',
    'edit item': 'Edit item',
    content: 'Content',
    'new course': 'New course',
    'new item': 'New item',
    padding: 'Padding',
    paddingMob: 'Padding Mob',
    paddingTab: 'Padding Tab',
    paddingDesk: 'Padding Desk',
    aspectRatio: 'Aspect ratio',
    'author avatar': 'Author avatar',
    'error required field': 'Required field',
    primary: 'Primary',
    secondary: 'Secondary',
    common: 'Common',
    outlined: 'Outlined',
    contactInfo: 'Contact Info',
    titleColor: 'Title color',
    subtitleColor: 'Subtitle color',
    twoColumnsCarousel: 'Two Columns Carousel',
    'btn-primary': 'Primary',
    'btn-secondary': 'Secondary',
    'btn-primary-outlined': 'Primary Outlined',
    'btn-secondary-outlined': 'Secondary Outlined',
    'type button': 'Type button',
    'add multimedia': 'Add Multimedia',
    'multimedia type': 'Multimedia Type',
    badges: 'Badges',
    dinamicTable: 'Dinamic Table',
    'multimedia gallery': 'Multimedia Gallery',
    blockFirstColumn: 'Block First Column',
    pubsGolf: 'Pubs Golf',
    pubsUlu: 'Pubs Ulu',
    'multimedia gallery': 'Multimedia Gallery',
    gradient: 'Gradient',
    'select section': 'Select section',
    'link type must not be empty': 'Link type must not be empty',
    'page must not be empty': 'Page must not be empty',
    'section must not be empty': 'Section must not be empty',
    'invert columns': 'Invert columns',
    'mobile gap': 'Mobile gap',
    'tablet gap': 'Tablet gap',
    'desktop gap': 'Desktop gap',
    'large desktop gap': 'Large desktop gap',
    bgColor: 'Background color',
    verticalPosition: 'Vertical Position',
    horizontalPosition: 'Horizontal Position',
    square: 'Sqare',
    rectangle: 'Rectangle',
    'title font size': 'Title Font Size',
    'subtitletitle font size': 'Subtitle Font Size',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    'related pubs title': 'Related publications title',
    'Label (max 3 characters)': 'Label (max 3 characters)',
    'mobile logo': 'Mobile logo',
  },
};

export default english;
